import { useState } from 'react'
import './carusel.css'
import { ReactComponent as ArrowL } from './../../media/svg/arrowL.svg'
import { ReactComponent as ArrowR } from './../../media/svg/arrowR.svg'
import { ReactComponent as Cross } from './../../media/svg/cross.svg'
import GetAltTag from '../../functions/getAltTag'

export default function Carusel({setCarusel,idClicked,imagesArray}){

   const [id, setId] = useState(idClicked)

   function HandleClick(value){
      if(value === -1 && id === 0){
         setId(imagesArray.length-1)
      }else if(value === 1 && id === imagesArray.length-1){
         setId(0)
      }else{
         setId(id+value)
      }
   }

   return(
      <div className='carusel-background'>
         <div className='carusel-flexIcon carusel-cross zoom' onClick={()=>setCarusel(0)}>
            <Cross/>
         </div>
         <div className='carusel-center'>

            <div className='carusel-flexIcon zoom' onClick={()=>{HandleClick(-1)}}>
               <ArrowL/>
            </div>
            
            <img src={imagesArray[id]} alt={GetAltTag(imagesArray[id])}/>

            <div className='carusel-flexIcon zoom' onClick={()=>{HandleClick(1)}}>
               <ArrowR/>               
            </div>
         </div>
      
      </div>
   )
}