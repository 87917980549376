import './css/home.css'
import { ReactComponent as Logo } from './../media/svg/logoHome.svg'
import { ReactComponent as Leaf } from './../media/svg/Leaf bio europe.svg'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Sfondo0 from "./../media/immagini/home/sfondo.jpg"

export default function Home({commonProps}){

   /* resettare lo scroll ogni volta che si cambia pagina */
   const location = useLocation();
   useEffect(() => {
      document.querySelector("html").scrollTo(0,0)
   }, [location.key]);
   /* resettare lo scroll ogni volta che si cambia pagina */

   var backgroundImage = ["url("+Sfondo0+")"]

    return(
        <div className="home" style={{"paddingTop":commonProps.navbarHeight+"px", backgroundImage: backgroundImage}} title="Background Image">
            <div className='home-background-gradient'/>
            <div className='home-logo-box'>
                <Logo className='home-logo' alt="logo-ca-lovisotto"/>
            </div>
            {/* <Leaf className='home-leaf'/> */}
        </div>
    )
}