import Sto1 from './../immagini/chi siamo/Chi siamo new.jpg'
import Sto2 from './../immagini/chi siamo/casali guarda fuoco.jpg'
import Sto3 from './../immagini/chi siamo/nonno Guerrino.jpg'
import Sto4 from './../immagini/chi siamo/luigi.jpg'
import Sto5 from './../immagini/chi siamo/cigni.jpg'
import Sto6 from './../immagini/chi siamo/foto casa.jpeg'
import Sto7 from './../immagini/chi siamo/donatella.jpg'
import Sto8 from './../immagini/chi siamo/terrazzamenti.jpg'
/* import Sto9 from './../immagini/chi siamo/Emmanuele.jpg' eliminata foto manu*/
import Sto10 from './../immagini/chi siamo/francesca.jpg'
import Sto11 from './../immagini/chi siamo/api.jpg'
import Sto12 from './../immagini/chi siamo/viti.jpg'
import Sto13 from './../immagini/chi siamo/famiglia vigna.jpg'
import Sto14 from './../immagini/chi siamo/manu fra b_n staccionata.jpg'

import Vino1 from './../immagini/vini/vino1.jpg'
import Vino2 from './../immagini/vini/vino2.jpg'
import Vino3 from './../immagini/tmp-vino.png'
import Vino4 from './../immagini/tmp-vino.png'

import Doc1 from './../doc/Mr.Love.pdf'
import Doc2 from './../doc/Mr.Love.pdf'
import Doc3 from './../doc/Mr.Love.pdf'
import Doc4 from './../doc/Mr.Love.pdf'

import Room1 from './../immagini/tmp.png'
import Room2 from './../immagini/tmp.png'
import Room3 from './../immagini/tmp.png'
import Room4 from './../immagini/tmp.png'

import NewsImg from './../immagini/tmp.png'

const testiPages = [
   {
      language: "IT",
      pages: [
         {
            title: "Chi siamo",
            blocks:[
               {
                  img: Sto1,
                  txt: [
                     "Siamo un’azienda a conduzione famigliare nella DOC Friuli Colli Orientali.",
                     <br/>,<br/>,
                     "Situata su una collina di Prepotto un tempo chiamata Guarda Fuoco, l’azienda si sviluppa su 3 ettari di vigneti a terrazza e altrettanti di bosco.",
                     <br/>,<br/>,
                     "Quando trovammo l’area, nel 2016, essa si trovava in totale stato di abbandono da oltre 15 anni, ma per noi era il posto giusto dove vivere in mezzo ad una natura incontaminata, produrre un vino biologico e poter offrire ai nostri ospiti un alloggio dalle vedute mozzafiato in un’atmosfera famigliare.",
                     <br/>,<br/>,
                     "Con grande passione e resilienza abbiamo recuperato i terrazzamenti, impiantato le barbatelle e restaurato gli edifici.",
                     <br/>,<br/>,
                     <i>Ed ora eccoci qui pronti a vivere il nostro sogno e a condividerlo con gli altri!</i>,
                     <br/>,<br/>,
                     "Generazioni diverse impegnate duramente in un progetto comune: l’enologo Luigi e la consorte Donatella, il figlio Emmanuele con la moglie Francesca, i quali guardano al futuro delle figlie, Johanna e Maria.",
                  ]
               },
               {
                  img: Sto2,
                  txt: [
                     ""
                  ]
               },
               {
                  img: Sto3,
                  txt: [
                     <b style={{"fontFamily": 'luiss_serifbold', fontSize: "22px"}}>Nonno Guerrino</b>,<br/>,<br/>,
                     "Chi è costui? Punto di riferimento fondamentale per la famiglia, egli nasce nel 1916 nella stalla, il luogo più sicuro della casa a quei tempi. Guerrino, il suo nome, ricorda il periodo in cui è nato, la Grande Guerra la quale lo ha portato a sopravvivere ad una delle peggiori esperienze del periodo, il campo di concentramento.",
                     <br/>,<br/>,
                     "Siamo nella Marca Trevigiana non lontano dalle sponde de fiume Piave. Uomo dalle mani laboriose, affezionato alle tradizioni ma dalla mentalità futuristica, rispetta il sapere antico riponendo fiducia nella tecnologia, il suo mantra: ",<i>“la scuola non è mai troppa”.</i>,
                     <br/>,<br/>,
                     "Aiutato nei campi dalla moglie, nonna Maria riesce ad ottenere apprezzabili risultati nella piccola azienda vitivinicola a Conegliano Veneto con un prodotto apprezzato unanimemente per la sua eccellente qualità."
                  ]
               },
               {
                  img: Sto4,
                  txt: [
                     <b style={{"fontFamily": 'luiss_serifbold', fontSize: "22px"}}>Luigi Lovisotto</b>,<br/>,<br/>,
                     "Cresciuto accanto al padre, anche coadiuvandolo nel lavoro dei campi, il figlio Luigi ne raccoglie i valori, i saperi e la passione per la terra. Luigi sceglie così di completare la sua formazione diplomandosi presso la Scuola Enologica di Conegliano Veneto, nel 1977.",
                     <br/>,<br/>,
                     "L’impegno nel lavoro, l’esperienza acquisita presso l’azienda Conti  di Collalto a Susegana e, successivamente, in veste dirigenziale presso la Tenuta Villabruna della famiglia Zoppas a Carlino (FVG), nonché la dedizione alla terra nel rispetto degli equilibri bio-ecosostenibili, gli valgono il riconoscimento del premio “Belleuropa Award 2010” presso il Parlamento Europeo a Bruxelles.",
                  ]
               },
               {
                  img: Sto5,
                  txt: [
                     "Nel 2014, con l’acquisizione del sito “Caneo” – Punta Sdobba (Grado), viene avviato un nuovo progetto finalizzato alla valorizzazione dell’area, riserva naturale delle foci dell’Isonzo. Sito di Importanza Comunitaria (SIC) e Zona di Protezione Speciale (ZPS), un ambiente privilegiato per l’osservazione e lo studio di flora e fauna in un’armonica interazione tra acqua, terra e cielo."
                  ]
               },
               {
                  img: Sto6,
                  txt: [
                     "Nel 2016 Luigi rileva, a Prepotto, l’attuale azienda vitivinicola, trovata in stato di abbandono da quasi 20 anni. Qui, sostenuto dalla presenza costante della moglie Donatella, instancabile creativa e appassionata di vita campestre, è partito il progetto di cantina biologica, in un contesto di biodiversità ed ecosostenibilità, e di ricezione turistica in casali dal design eterogeneo ma curato e confortevole.",
                  ]
               },
               {
                  img: Sto7,
                  txt: [
                     "Donatella, appassionata da sempre per l’interior design, i fiori, il fai da te e il collezionismo, si dedica all’azienda con la realizzazione di ambienti che conservino quel fascino e quella storia che da sempre hanno caratterizzato i“casali Guardafuoco” circa un centinaio di anni fa sulle colline di Poianis di Prepotto. Sua l’idea di recuperare alcuni degli originari colori degli edifici e di dare spazio a diversi stili.",
                     <br/>,<br/>,
                     "Per Ca’ Lovisotto il suo obiettivo è ricreare un ambiente di gusto ma senza sfoggio, eterogeneo ma equilibrato, piacevole e rilassante nel movimento frenetico della vita moderna, rispettando ed assecondando la disposizione sia interna che esterna delle diverse aree, con scelte che trasmettano naturalezza e semplicità unita a classe e buon gusto."
                  ]
               },
               {
                  img: Sto8,
                  txt: [
                     "Certificata biologica nel 2021, Ca’ Lovisotto è un connubio tra un antico bosco lussureggiante e vigneti a terrazzo, alternati ad alberi da frutto ed erbe spontanee autoctone, dove una ricca fauna vive in armonia, indisturbata e rispettata.",
                     <br/>,<br/>,
                     "Dagli alloggi si possono ammirare dei panorami collinari incantevoli. L’alba al mattino e il tramonto alla sera tingono di rosa tutto l’ambiente, mentre nelle notti stellate sembra di poter toccare il cielo con un dito.",
                     <br/>,<br/>,
                     "Questo è il luogo ideale dove staccarsi dalla frenesia quotidiana e godere della tranquillità della natura, meditando con un calice di vino.",
                  ]
               },
               {
                  img: Sto13,
                  txt: [
                     ""
                  ]
               },
               {
                  img: Sto14,
                  txt: [
                     <b style={{"fontFamily": 'luiss_serifbold', fontSize: "22px"}}>Il progetto continua…</b>,<br/>,<br/>,
                     <b style={{"fontFamily": 'luiss_serifbold', fontSize: "22px"}}>Il presente e il futuro: Emmanuele Lovisotto e Francesca, Johanna e Maria</b>,<br/>,<br/>,
                     "L’idea di Luigi e Donatella viene accolta dal figlio Emmanuele e dalla nuora Francesca.",
                     <br/>,<br/>,
                     "Il loro sogno è di vivere in un ambiente incontaminato e continuare con devozione e passione le tradizioni di famiglia.",
                     <br/>,<br/>,
                     "Emmanuele, grazie alla sua professione come pilota ha conosciuto molte realtà e culture diverse e proprio in occasione dei suoi viaggi ha scoperto antiche tradizioni legate al vino. Una di queste è l’utilizzo delle anfore, una pratica risalente alla civiltà mesopotamica, introdotta in azienda in chiave moderna. Infatti, per l’affinamento della Ribolla Gialla vengono usate anfore di terracotta naturale.",
                     <br/>,<br/>,
                     "Il credere nel valore e nel potenziale del piccolo, della qualità e della famiglia sono i motori che spingono le ali di Emmanuele nel perseverare in questo sano progetto."
                  ]
               },
               {
                  img: Sto10,
                  txt: [
                     "L’amore per tutto ciò che è natura, benessere olistico e cultura della moglie Francesca, esperta di lingue e di letteratura, nonché profondamente interessata a tutte le manifestazioni artistiche, completano la vision di questo progetto, il quale mira a rendere l’azienda una piccola opera d’arte a disposizione degli ospiti."
                  ]
               },
               {
                  img: Sto11,
                  txt: [
                     "Oltre ai vini,  agli alloggi e alla natura, Ca’ Lovisotto offre miele totalmente naturale grazie alla presenza di arnie d’api in un ambiente lussureggiate e di ricco di biodiversità."
                  ]
               },
               {
                  img: Sto12,
                  txt: [
                     ""
                  ]
               }
            ]
         },
         {
            title: "I vini",
            vini: [
               {
                  title: "Schioppettino di Prepotto Doc Friuli Colli Orientali",
                  info: [
                     {
                        title: "Annata",
                        text: "2021"
                     },
                     {
                        title: "Zona di Produzione",
                        text: "Prepotto (UD), Località Poianis, altitudine 120 mt slm"
                     },
                     {
                        title: "Varietà",
                        text: "100% Schioppettino, vitigno storico autoctono coltivato in Friuli dalla metà del 1800"
                     },
                     {
                        title: "Terreno",
                        text: "Ponca, flysch costituito da marne ed arenarie stratificate di origine eocenica"
                     },
                     {
                        title: "Esposizione vigneti",
                        text: "Nord-est, sud-est"
                     },
                     {
                        title: "Sistema di allevamento",
                        text: "Guyot"
                     },
                     {
                        title: "Anno impianto",
                        text: "2019"
                     },
                     {
                        title: "Vendemmia",
                        text: "Manuale, fine settembre"
                     },
                     {
                        title: "Vinificazione e affinamento",
                        text: "Vinificazione in rosso con macerazione prolungata con durata di circa 12/15 giorni. Fermentazione a temperatura controllata (da + 14 a max 28°C.) per favorire lo sviluppo e una maggiore cessione degli aromi varietali. Affinato in vasca inox per 5/6 mesi, poi passaggio in barriques e tonneaux di legni diversi per 12 mesi, infine in bottiglia con successiva conservazione in cantina sotterranea per almeno 8/9 mesi"
                     },
                     {
                        title: "Tipo di bottiglia e chiusura",
                        text: "Bordolese da 0.750 l, tappo in sughero monopezzo"
                     },
                     {
                        title: "Produzione",
                        text: "5000 bottiglie/ha/anno"
                     },
                     {
                        title: "Note Organolettiche",
                        text: ""
                     },
                     {
                        title: "Colore",
                        text: "Colore rosso rubino di intensità variabile, con leggere sfumature granate"
                     },
                     {
                        title: "Profumo",
                        text: "Spiccato sentore di note speziate (pepe nero, cannella, foglia di tabacco), fondo di caffè e confettura di frutti di bosco"
                     },
                     {
                        title: "Sapore",
                        text: "Al palato offre gradevoli sensazioni di spezie e ribes rosso. Vino di ottimo corpo, pieno, equilibrato e di piacevole persistenza"
                     },
                     {
                        title: "Gradazione",
                        text: "13.50° % Vol."
                     },
                     {
                        title: "Temperatura di servizio",
                        text: "+ 18°/20°C, si consiglia di stappare la bottiglia un’ora prima del servizio preferibilmente con decanter"
                     },
                     {
                        title: "Abbinamenti",
                        text: "Ottimo con piatti di carni rosse, selvaggina, formaggi saporiti, ricercato con l’accostamento con le lumache e pesci saporiti"
                     },
                  ],
                  img: Vino1,
               },
               {
                  title: "Ribolla Gialla Doc Friuli Colli Orientali",
                  info: [
                     {
                        title: "Annata",
                        text: "2022"
                     },
                     {
                        title: "Zona di Produzione",
                        text: "Prepotto (UD), Località Poianis, altitudine 120 mt slm"
                     },
                     {
                        title: "Varietà",
                        text: "100% Ribolla Gialla, vitigno autoctono"
                     },
                     {
                        title: "Terreno",
                        text: "Ponca, flysch costituito da marne ed arenarie stratificate di origine eocenica"
                     },
                     {
                        title: "Esposizione vigneti",
                        text: "Nord-est, sud-est"
                     },
                     {
                        title: "Sistema di allevamento",
                        text: "Guyot "
                     },
                     {
                        title: "Anno impianto",
                        text: "2019"
                     },
                     {
                        title: "Vendemmia",
                        text: "Manuale, prima metà di settembre"
                     },
                     {
                        title: "Vinificazione e affinamento",
                        text: "Macerazione dell’uva per 6/8 ore a temperatura +8°C, pressatura soffice con resa in mosto 65% portato a fermentazione su letto di lievito indigeno alla temperatura controllata costante a + 15°C per 12 giorni. Primo travaso del vino biologico, in vasca inox secondo travaso in anfora di terracotta naturale per 7 mesi."
                     },
                     {
                        title: "Tipo di bottiglia e chiusura",
                        text: "Bordolese da 0.750 ml, tappo Stelvin"
                     },
                     {
                        title: "Produzione",
                        text: "5000 bottiglie/anno"
                     },
                     {
                        title: "Note Organolettiche",
                        text: ""
                     },
                     {
                        title: "Colore",
                        text: "Giallo brillante con tonalità dorate"
                     },
                     {
                        title: "Profumo",
                        text: "Delicato, elegante, floreale con un ventaglio di note dolci agrumate e minerali."
                     },
                     {
                        title: "Sapore",
                        text: "Asciutto, gentile di ottima struttura, tannino dolce fuso con note di cera d’api, acidità e mineralità equilibrate"
                     },
                     {
                        title: "Gradazione",
                        text: "12.50° % Vol."
                     },
                     {
                        title: "Temperatura di servizio",
                        text: "+ 12°C"
                     },
                     {
                        title: "Abbinamenti",
                        text: "Piatti di pesce e carni bianche in salse delicate, piatti freddi estivi, ottimo con formaggi freschi, si presta ad accompagnare pietanze vegetariane e vegane."
                     },
                  ],
                  img: Vino2,
               }
            ],
            download: "Più informazioni",
            info: "Chiedi maggiori informazioni",
            infoLink: "/it/contattaci"
         },
         {
            wines: [
               {
                  title: "Camera 1",
                  descTitle: "Due righe di titolo",
                  descTxt: "Testo descrittivo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum, vitae lectus neque, fermentum id. Quis in purus vestibulum nulla adipiscing sagittis fringilla orci. Justo, sed aliquet mi diam. Quam convallis ipsum proin velit.",
                  mainImg: Room1,
                  info: "Chiedi maggiori informazioni",
                  infoLink: "/it/contattaci"
               },
               {
                  title: "Camera 2",
                  descTitle: "Due righe di titolo",
                  descTxt: "Testo descrittivo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum, vitae lectus neque, fermentum id. Quis in purus vestibulum nulla adipiscing sagittis fringilla orci. Justo, sed aliquet mi diam. Quam convallis ipsum proin velit.",
                  mainImg: Room2,
                  info: "Chiedi maggiori informazioni",
                  infoLink: "/it/contattaci"
               },
               {
                  title: "Camera 3",
                  descTitle: "Due righe di titolo",
                  descTxt: "Testo descrittivo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum, vitae lectus neque, fermentum id. Quis in purus vestibulum nulla adipiscing sagittis fringilla orci. Justo, sed aliquet mi diam. Quam convallis ipsum proin velit.",
                  mainImg: Room3,
                  info: "Chiedi maggiori informazioni",
                  infoLink: "/it/contattaci"
               },
               {
                  title: "Camera 4",
                  descTitle: "Due righe di titolo",
                  descTxt: "Testo descrittivo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum, vitae lectus neque, fermentum id. Quis in purus vestibulum nulla adipiscing sagittis fringilla orci. Justo, sed aliquet mi diam. Quam convallis ipsum proin velit.",
                  mainImg: Room4,
                  info: "Chiedi maggiori informazioni",
                  infoLink: "/it/contattaci"
               }
            ]
         },
         {
            title: "L'agriturismo",
            rooms: [
               {
                  title: "Nome camera",
                  description: "Descrizione Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  img: Room1,
                  path: "/camera/1"
               },
               {
                  title: "Nome camera",
                  description: "Descrizione Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  img: Room2,
                  path: "/camera/2"
               },
               {
                  title: "Nome camera",
                  description: "Descrizione Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  img: Room3,
                  path: "/camera/3"
               },
               {
                  title: "Nome camera",
                  description: "Descrizione Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  img: Room4,
                  path: "/camera/4"
               }
            ],
            labelPath: "Più informazioni"
         },
         {
            rooms: [
               {
                  title: "Camera 1",
                  descTitle: "Due righe di titolo",
                  descTxt: "Testo descrittivo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum, vitae lectus neque, fermentum id. Quis in purus vestibulum nulla adipiscing sagittis fringilla orci. Justo, sed aliquet mi diam. Quam convallis ipsum proin velit.",
                  mainImg: Room1,
                  info: "Chiedi maggiori informazioni",
                  infoLink: "/it/contattaci"
               },
               {
                  title: "Camera 2",
                  descTitle: "Due righe di titolo",
                  descTxt: "Testo descrittivo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum, vitae lectus neque, fermentum id. Quis in purus vestibulum nulla adipiscing sagittis fringilla orci. Justo, sed aliquet mi diam. Quam convallis ipsum proin velit.",
                  mainImg: Room2,
                  info: "Chiedi maggiori informazioni",
                  infoLink: "/it/contattaci"
               },
               {
                  title: "Camera 3",
                  descTitle: "Due righe di titolo",
                  descTxt: "Testo descrittivo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum, vitae lectus neque, fermentum id. Quis in purus vestibulum nulla adipiscing sagittis fringilla orci. Justo, sed aliquet mi diam. Quam convallis ipsum proin velit.",
                  mainImg: Room3,
                  info: "Chiedi maggiori informazioni",
                  infoLink: "/it/contattaci"
               },
               {
                  title: "Camera 4",
                  descTitle: "Due righe di titolo",
                  descTxt: "Testo descrittivo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum, vitae lectus neque, fermentum id. Quis in purus vestibulum nulla adipiscing sagittis fringilla orci. Justo, sed aliquet mi diam. Quam convallis ipsum proin velit.",
                  mainImg: Room4,
                  info: "Chiedi maggiori informazioni",
                  infoLink: "/it/contattaci"
               }
            ]
         },
         {
            title: "Fotogallery",
            tip: "Vedi tutte le foto"
         },
         {
            title: "News",
            newsArray: [
               {
                  title: "Titolo news",
                  description: "Descrizione Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  img: NewsImg,
               },
               {
                  title: "Titolo news",
                  description: "Descrizione Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  img: NewsImg,
               },
               {
                  title: "Titolo news",
                  description: "Descrizione Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  img: NewsImg,
               },
               {
                  title: "Titolo news",
                  description: "Descrizione Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  img: NewsImg,
               },
               {
                  title: "Titolo news",
                  description: "Descrizione Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  img: NewsImg,
               },
               {
                  title: "Titolo news",
                  description: "Descrizione Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  img: NewsImg,
               },
               {
                  title: "Titolo news",
                  description: "Descrizione Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  img: NewsImg,
               },
            ]
         },
         {
            title: "Contattaci",
            subt1: "Richiedi informazioni",
            form: {
               fields: [
                  {
                     title: "Nome*",
                     placeholder: "Inserisci il nome"
                  },
                  {
                     title: "Cognome*",
                     placeholder: "Inserisci il cognome"
                  },
                  {
                     title: "Email*",
                     placeholder: "Inserisci l'email"
                  },
                  {
                     title: "Cellulare",
                     placeholder: "Inserisci il cellulare"
                  },
                  {
                     title: "Messaggio*",
                     placeholder: "Inserisci il messaggio"
                  },
               ],
               privacy: {
                  txt: {
                     accetto: "Accetto la ",
                     link: "privacy policy."
                  },
                  link: "privacy-policy"
               },
               button: "Invia"
            },
            subt2: "Dove siamo",
            where: {
               name: "Ca’ Lovisotto",
               address: "Via Ronchi, 70, 33040 Poianis UD",
               mapBtn: {
                  txt: "Indicazioni",
                  link: "https://goo.gl/maps/NPvXfZUiy5svemhc6"
               }
            },
            popUp: [
               "Invio email...",
               "Email inviata.",
               "Errore nell'invio della email: riprovare"
            ]
         },
         {
            title: "Privacy policy",
            txt: [
               <b style={{"fontFamily": 'luiss_serifbold', fontSize: "22px"}}>Title</b>,<br/>,<br/>,
               "txt",
               <br/>,<br/>,
               "txt",
               <br/>,<br/>,
               "txt"
            ]
         }
      ]
   },
   {
      language: "EN",
      pages: [
         {
            title: "Who we are",
            blocks:[
               {
                  img: Sto1,
                  txt: [
                     "We are a family-run winery in the PDO Friuli Colli Orientali.",
                     <br/>,<br/>,
                     "Located on a hill in Prepotto, once called Guarda Fuoco; the farm extends over 3 hectares of slopes and 3 hectares of woodland.",
                     <br/>,<br/>,
                     "When we found the area in 2016, it was completely abandoned for over 15 years. But for us it was the right place to live amidst unspoiled nature, produce organic wine and offer our guests accommodation with breathtaking views, in a family and cozy atmosphere.",
                     <br/>,<br/>,
                     "With great passion and resilience, we restored the terraces, planted the vines, and renovated the buildings.",
                     <br/>,<br/>,
                     <i>And now here we are, ready to live our dream and share it with you!</i>,
                     <br/>,<br/>,
                     "Different generations committed to a common project: the winemaker Luigi and his wife Donatella, their son Emmanuele and his wife Francesca, who look to the future of their daughters, Johanna and Maria.",
                  ]
               },
               {
                  img: Sto2,
                  txt: [
                     ""
                  ]
               },
               {
                  img: Sto3,
                  txt: [
                     <b style={{"fontFamily": 'luiss_serifbold', fontSize: "22px"}}>Grandfather Guerrino</b>,<br/>,<br/>,
                     "Who is he? A lighthouse for the family, he was born in 1916 in a stable, the safest place in the house at that time. Guerrino, his name, recalls the period in which he was born, the Great War which led him to survive one of the worst experiences of that time, the concentration camp.",
                     <br/>,<br/>,
                     "We are in the Marca Trevigiana not far from the banks of the Piave river. A man with industrious hands, traditions’ lover but with a futuristic mentality, he respects ancient knowledge while trusting technology. His mantra: ",<i>“one can never have too much education and knowledge”.</i>,
                     <br/>,<br/>,
                     "Assisted in the fields by his wife, grandmother Maria, manages to achieve good results in his small winery in Conegliano Veneto with a wine unanimously appreciated for its excellent quality."
                  ]
               },
               {
                  img: Sto4,
                  txt: [
                     <b style={{"fontFamily": 'luiss_serifbold', fontSize: "22px"}}>Luigi Lovisotto</b>,<br/>,<br/>,
                     "Growing with his father and assisting him in the field works, Luigi inherits his father's values, knowledge, and passion for the land. Luigi chooses to further his education by graduating from the Enological School of Conegliano Veneto in 1977.",
                     <br/>,<br/>,
                     "His dedication to work, the experience gained at the Conti di Collalto farm (Susegana), and his managerial roles at Villabruna Farm of Zoppas family in Carlino, as well as his devotion to the land in respect of bio-eco sustainable values, earned him the recognition of the “Belleuropa Award 2010” at the European Parliament (Brussels).",
                  ]
               },
               {
                  img: Sto5,
                  txt: [
                     "The acquisition of the “Caneo” site – Punta Sdobba (Grado), has started a new project aimed at enhancing the wine area with the lagoon. The natural reserve of the Isonzo river is a Site of Community Importance (SCI) and Special Protection Area (SPA), a privileged environment for observing and studying flora and fauna in a harmonious interaction between water, land, and sky."
                  ]
               },
               {
                  img: Sto6,
                  txt: [
                     "In 2016, Luigi acquired the current winery in Prepotto. Supported by the constant presence of his wife Donatella, tireless, creative and lover of country life, the project of an organic winery began. In a context of biodiversity and eco-sustainability we offer accommodation in different styles and comfortable designs.",
                  ]
               },
               {
                  img: Sto7,
                  txt: [
                     "Donatella, always passionate about interior design, flowers, do-it-yourself projects and collecting, dedicates herself to the winery by creating environments that preserve the charm and history of the local vineyards. It was her the idea to recover some of the original colors of the buildings.",
                     <br/>,<br/>,
                     "For Ca’ Lovisotto, her aim is to recreate a vintage yet elegant design with a pleasant and relaxing atmosphere to relieve guest from the frenzy of modern life, respecting and complementing both the internal and external disposition of the different areas, with choices that convey naturality and simplicity combined with class and good taste."
                  ]
               },
               {
                  img: Sto8,
                  txt: [
                     "Certified organic in 2021, Ca’ Lovisotto is a blend of an ancient lush forest and sloped vineyards, with fruit trees and native wild herbs, where a rich fauna lives in harmony, undisturbed and respected.",
                     <br/>,<br/>,
                     "From the accommodations, one can admire enchanting hillside panoramas. The morning sunrise and evening sunset paint everything in pink, while on starry nights, you can touch the sky with your finger.",
                     <br/>,<br/>,
                     "This is the ideal place to disconnect from the daily hustle and bustle and enjoy the tranquility of nature, meditating with a glass of wine.",
                  ]
               },
               {
                  img: Sto13,
                  txt: [
                     ""
                  ]
               },
               {
                  img: Sto14,
                  txt: [
                     <b style={{"fontFamily": 'luiss_serifbold', fontSize: "22px"}}>The project continues...</b>,<br/>,<br/>,
                     <b style={{"fontFamily": 'luiss_serifbold', fontSize: "22px"}}>The present and the future: Emmanuele Lovisotto and Francesca, Johanna and Maria.</b>,<br/>,<br/>,
                     "The idea of Luigi and Donatella is embraced by their son Emmanuele and his wife Francesca.",
                     <br/>,<br/>,
                     "Their dream is to live in an uncontaminated environment and continue with devotion and passion the family traditions.",
                     <br/>,<br/>,
                     "Emmanuele, thanks to his profession as a pilot, has met many different realities and cultures, and it was during one of his travels that he discovered ancient traditions related to wine. One of these is the use of amphorae, a practice dating back to Mesopotamian era, introduced to the farm in a modern context. In fact, natural terracotta amphorae are used for the aging of our Ribolla Gialla.",
                     <br/>,<br/>,
                     "Strong belief in the value and potential of small production, of quality, and of family are the driving forces that propel Emmanuele's wings in persevering with this valuable project."
                  ]
               },
               {
                  img: Sto10,
                  txt: [
                     "The love for everything that is natural, in holistic well-being, and culture Francesca, an expert in languages and literature, as well as deeply interested in all arts, complements the vision of this project. She is looking to make the winery a prestigious artwork for her guests."
                  ]
               },
               {
                  img: Sto11,
                  txt: [
                     "In addition to wines, accommodations, and nature, Ca’ Lovisotto offers completely natural honey thanks to the presence of beehives in a lush and biodiverse environment."
                  ]
               },
               {
                  img: Sto12,
                  txt: [
                     ""
                  ]
               }
            ]
         },
         {
            title: "Wines",
            vini: [
               {
                  title: "Schioppettino di Prepotto Doc Friuli Colli Orientali",
                  info: [
                     {
                        title: "Vintage",
                        text: "2021"
                     },
                     {
                        title: "Production Area",
                        text: "Prepotto (UD), Locality Poianis, altitude 120 meters above sea level"
                     },
                     {
                        title: "Variety",
                        text: "100% Schioppettino, a historic indigenous grape variety cultivated in Friuli since the mid-1800s"
                     },
                     {
                        title: "Soil",
                        text: "Ponca, flysch consisting of marl and stratified sandstone of Eocene origin"
                     },
                     {
                        title: "Vineyard Exposure",
                        text: "Northeast, southeast"
                     },
                     {
                        title: "Training System",
                        text: "Guyot"
                     },
                     {
                        title: "Planting Year",
                        text: "2019"
                     },
                     {
                        title: "Harvest",
                        text: "Manual, late September"
                     },
                     {
                        title: "Winemaking and Aging",
                        text: "Red vinification with prolonged maceration lasting about 12/15 days. Fermentation at controlled temperature (from +14 to max 28°C) to promote development and greater release of varietal aromas. Aged in stainless steel tanks for 5/6 months, then transferred to barrels and tonneau of different woods for 12 months, finally bottled with subsequent cellar aging for at least 8/9 months"
                     },
                     {
                        title: "Bottle Type and Cap",
                        text: "Bordeaux bottle of 0.750 liters, one-piece cork cap"
                     },
                     {
                        title: "Production",
                        text: "5000 bottles/ha/year"
                     },
                     {
                        title: "Organoleptic Notes",
                        text: ""
                     },
                     {
                        title: "Color",
                        text: "Ruby red color of variable intensity, with light garnet hues"
                     },
                     {
                        title: "Aroma",
                        text: "Distinctive aroma of spicy notes (black pepper, cinnamon, tobacco leaves), background of coffee and wild berry jam"
                     },
                     {
                        title: "Flavors",
                        text: "It offers pleasant sensations of spices and red currants. Wine of excellent body, full, balanced, and pleasantly persistent"
                     },
                     {
                        title: "Alcohol",
                        text: "13.50% abv."
                     },
                     {
                        title: "Serving Temperature",
                        text: "+18°/20°C, it is recommended to uncork the bottle one hour before serving, preferably with a decanter"
                     },
                     {
                        title: "Pairing",
                        text: "Excellent with red meats, game, flavorful cheeses, exquisite when paired with snails and flavorful fish"
                     },
                  ],
                  img: Vino1,
               },
               {
                  title: "Ribolla Gialla Doc Friuli Colli Orientali",
                  info: [
                     {
                        title: "Vintage",
                        text: "2022"
                     },
                     {
                        title: "Production Area",
                        text: "Prepotto (UD), Locality Poianis, altitude 120 meters above sea level"
                     },
                     {
                        title: "Variety",
                        text: "100% Ribolla Gialla, indigenous grape variety"
                     },
                     {
                        title: "Soil",
                        text: "Ponca, flysch consisting of marl and stratified sandstone of Eocene origin"
                     },
                     {
                        title: "Vineyard Exposure",
                        text: "Northeast, southeast"
                     },
                     {
                        title: "Training System",
                        text: "Guyot "
                     },
                     {
                        title: "Planting Year",
                        text: "2019"
                     },
                     {
                        title: "Harvest",
                        text: "Manual, first half of September"
                     },
                     {
                        title: "Winemaking and Aging",
                        text: "Grape maceration for 6/8 hours at +8°C, gentle pressing with a yield of 65% must fermented on a bed of indigenous yeast at a constant controlled temperature of +15°C for 12 days. First racking of the organic wine, in stainless steel tank, second racking in natural terracotta amphora for 6/8 months."
                     },
                     {
                        title: "Bottle Type and Cap",
                        text: "Bordeaux 0.750 ml, Stelvin cap"
                     },
                     {
                        title: "Production",
                        text: "5000 bottles/year"
                     },
                     {
                        title: "Organoleptic Notes",
                        text: ""
                     },
                     {
                        title: "Color",
                        text: "Bright yellow with golden hues"
                     },
                     {
                        title: "Aroma",
                        text: "Delicate, elegant, floral with a range of sweet citrus and mineral notes"
                     },
                     {
                        title: "Flavors",
                        text: "Taste Dry, gentle with excellent structure, sweet tannin fused with notes of beeswax, balanced acidity and minerality"
                     },
                     {
                        title: "Alcohol",
                        text: "12.50% Vol."
                     },
                     {
                        title: "Temperatura di servizio",
                        text: "+ 12°C"
                     },
                     {
                        title: "Pairing",
                        text: "Fish and white meat dishes with delicate sauces, summer cold dishes, excellent with fresh cheeses, suitable for accompanying vegetarian and vegan dishes."
                     },
                  ],
                  img: Vino2,
               }
            ],
            download: "More infos",
            info: "Ask more infos",
            infoLink: "/en/contact-us"
         },
         {
            wines: [
               {
                  title: "Camera 1",
                  descTitle: "Due righe di titolo",
                  descTxt: "Testo descrittivo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum, vitae lectus neque, fermentum id. Quis in purus vestibulum nulla adipiscing sagittis fringilla orci. Justo, sed aliquet mi diam. Quam convallis ipsum proin velit.",
                  mainImg: Room1,
                  info: "Ask more infos",
                  infoLink: "/en/contact-us"
               },
               {
                  title: "Camera 2",
                  descTitle: "Due righe di titolo",
                  descTxt: "Testo descrittivo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum, vitae lectus neque, fermentum id. Quis in purus vestibulum nulla adipiscing sagittis fringilla orci. Justo, sed aliquet mi diam. Quam convallis ipsum proin velit.",
                  mainImg: Room2,
                  info: "Ask more infos",
                  infoLink: "/en/contact-us"
               },
               {
                  title: "Camera 3",
                  descTitle: "Due righe di titolo",
                  descTxt: "Testo descrittivo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum, vitae lectus neque, fermentum id. Quis in purus vestibulum nulla adipiscing sagittis fringilla orci. Justo, sed aliquet mi diam. Quam convallis ipsum proin velit.",
                  mainImg: Room3,
                  info: "Ask more infos",
                  infoLink: "/en/contact-us"
               },
               {
                  title: "Camera 4",
                  descTitle: "Due righe di titolo",
                  descTxt: "Testo descrittivo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum, vitae lectus neque, fermentum id. Quis in purus vestibulum nulla adipiscing sagittis fringilla orci. Justo, sed aliquet mi diam. Quam convallis ipsum proin velit.",
                  mainImg: Room4,
                  info: "Ask more infos",
                  infoLink: "/en/contact-us"
               }
            ]
         },
         {
            title: "Farmhouse",
            rooms: [
               {
                  title: "Room name",
                  description: "Descrizione Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  img: Room1,
                  path: "/room/1"
               },
               {
                  title: "Room name",
                  description: "Descrizione Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  img: Room2,
                  path: "/room/2"
               },
               {
                  title: "Room name",
                  description: "Descrizione Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  img: Room3,
                  path: "/room/3"
               },
               {
                  title: "Room name",
                  description: "Descrizione Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  img: Room4,
                  path: "/room/4"
               }
            ],
            labelPath: "More infos"
         },
         {
            rooms: [
               {
                  title: "Camera 1",
                  descTitle: "Due righe di titolo",
                  descTxt: "Testo descrittivo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum, vitae lectus neque, fermentum id. Quis in purus vestibulum nulla adipiscing sagittis fringilla orci. Justo, sed aliquet mi diam. Quam convallis ipsum proin velit.",
                  mainImg: Room1,
                  info: "Ask more infos",
                  infoLink: "/en/contact-us"
               },
               {
                  title: "Camera 2",
                  descTitle: "Due righe di titolo",
                  descTxt: "Testo descrittivo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum, vitae lectus neque, fermentum id. Quis in purus vestibulum nulla adipiscing sagittis fringilla orci. Justo, sed aliquet mi diam. Quam convallis ipsum proin velit.",
                  mainImg: Room2,
                  info: "Ask more infos",
                  infoLink: "/en/contact-us"
               },
               {
                  title: "Camera 3",
                  descTitle: "Due righe di titolo",
                  descTxt: "Testo descrittivo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum, vitae lectus neque, fermentum id. Quis in purus vestibulum nulla adipiscing sagittis fringilla orci. Justo, sed aliquet mi diam. Quam convallis ipsum proin velit.",
                  mainImg: Room3,
                  info: "Ask more infos",
                  infoLink: "/en/contact-us"
               },
               {
                  title: "Camera 4",
                  descTitle: "Due righe di titolo",
                  descTxt: "Testo descrittivo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum, vitae lectus neque, fermentum id. Quis in purus vestibulum nulla adipiscing sagittis fringilla orci. Justo, sed aliquet mi diam. Quam convallis ipsum proin velit.",
                  mainImg: Room4,
                  info: "Ask more infos",
                  infoLink: "/en/contact-us"
               }
            ]
         },
         {
            title: "Photogallery",
            tip: "Show all photos"
         },
         {
            title: "News",
            newsArray: [
               {
                  title: "Titolo news",
                  description: "Descrizione Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  img: NewsImg,
               },
               {
                  title: "Titolo news",
                  description: "Descrizione Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  img: NewsImg,
               },
               {
                  title: "Titolo news",
                  description: "Descrizione Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  img: NewsImg,
               },
               {
                  title: "Titolo news",
                  description: "Descrizione Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  img: NewsImg,
               },
               {
                  title: "Titolo news",
                  description: "Descrizione Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  img: NewsImg,
               },
               {
                  title: "Titolo news",
                  description: "Descrizione Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  img: NewsImg,
               },
               {
                  title: "Titolo news",
                  description: "Descrizione Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  img: NewsImg,
               },
            ]
         },
         {
            title: "Contact us",
            subt1: "Ask more infos",
            form: {
               fields: [
                  {
                     title: "Name*",
                     placeholder: "Enter name"
                  },
                  {
                     title: "Surname*",
                     placeholder: "Enter surname"
                  },
                  {
                     title: "Email*",
                     placeholder: "Enter email"
                  },
                  {
                     title: "Cell phone",
                     placeholder: "Enter cell phone"
                  },
                  {
                     title: "Message*",
                     placeholder: "Enter message"
                  },
               ],
               privacy: {
                  txt: {
                     accetto: "I accept the ",
                     link: "privacy policy."
                  },
                  link: "privacy-policy"
               },
               button: "Send"
            },
            subt2: "Where we are",
            where: {
               name: "Ca’ Lovisotto",
               address: "Ronchi street, 70, 33040 Poianis UD",
               mapBtn: {
                  txt: "Directions",
                  link: "https://goo.gl/maps/NPvXfZUiy5svemhc6"
               }
            },
            popUp: [
               "Sending email...",
               "Email sent.",
               "Error sending the email. Retry."
            ]
         },
         {
            title: "Privacy policy",
            txt: [
               <b style={{"fontFamily": 'luiss_serifbold', fontSize: "22px"}}>Title</b>,<br/>,<br/>,
               "txt",
               <br/>,<br/>,
               "txt",
               <br/>,<br/>,
               "txt"
            ]
         }
      ]
   }
]

export default testiPages;