import { useState } from 'react'
import Footer from '../footer/footer'
import Navbar from '../navbar/navbar'
import './wrapper.css'

import { ReactComponent as Msg } from './../../media/svg/contattaci.svg'
import { useNavigate } from 'react-router-dom'

export default function Wrapper(props){

    const [hideBurger, setHideBurger] = useState(0)

    const navigate = useNavigate()

    function changePage(){

        const langArray = ["it","en"]
        let path = ["contattaci","contact-us"] 

        navigate("/"+langArray[props.wrapperProps.lang]+"/"+path[props.wrapperProps.lang])
    }

    return(
        <div className="wrapper">
            <Navbar lang={props.wrapperProps.lang} setLang={props.wrapperProps.setLang} setNavbarHeight={props.wrapperProps.setNavbarHeight} setHideBurger={setHideBurger}/>
            <div style={hideBurger?{"display":"none"}:{}}>
                {props.children}
            </div>
            <Footer hideBurger={hideBurger} lang={props.wrapperProps.lang}/>
            <div className='fixed-contattaci' style={hideBurger?{"display":"none"}:{}}
                onClick={()=>changePage()}>
                <Msg/>
            </div>
        </div>
    )
}