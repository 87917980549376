import './css/agriturismo.css'
import { ReactComponent as Info } from './../media/svg/info.svg'
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export default function Agriturismo({commonProps}){
   
   const testi = commonProps.testi[3];

   
   /* resettare lo scroll ogni volta che si cambia pagina */
   const location = useLocation();
   useEffect(() => {
      document.querySelector("html").scrollTo(0,0)
   }, [location.key]);  
   /* resettare lo scroll ogni volta che si cambia pagina */

   return(
      <div className="mainPage" style={{"marginTop":commonProps.navbarHeight+"px"}}>
         <h1>{testi.title}</h1>
         <div className='vini-frame'>
            {testi.rooms.map((el,i)=>{
               return <Room key={i} el={el} testi={testi}/>
            })}
         </div>
      </div>
   )
}

function Room({el,testi}){

   const navigate = useNavigate()

   const link = window.location.pathname + el.path

   return(
      <div className='rooms-box ombra'>
         <img src={el.img} alt={el.title}/>

         <div className='rooms-box-txt'>
            <div className='footer-title'>{el.title}</div>
            <div className='normalTxt'>{el.description}</div>            
         </div>

         <div className='emptyButton zoom' onClick={()=>navigate(link)}>
            <Info className='secondaryCta-svg'/>
            {testi.labelPath}
         </div>
      </div>
   )
}