import { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import './media/font/stylesheet.css';
import Wrapper from './components/wrapper/wrapper';
import testiPages from './media/testi/pages';
import Agriturismo from './pages/agriturismo';
import Room from './pages/camera';
import Contattaci from './pages/contattaci';
import Fotogallery from './pages/fotogallery';

import Home from './pages/home'
import News from './pages/news';
import Storia from './pages/storia';
import Vini from './pages/vini';
import Vino from './pages/vino';
import Privacy from './pages/privacy';

function App() {

  const [lang, setLang] = useState (0) /* 0 = it, 1 = en */

  const [navbarHeight, setNavbarHeight] = useState()

  useEffect(()=>{
    /* handling errore link */
    if(window.location.pathname.substring(1,3) !== "it" && window.location.pathname.substring(1,3) !== "en" ){
      window.location.pathname = "/it"
    }

    if(window.location.pathname.substring(1,3) === "it"){
      document.head.children[4].content = "Ca' Lovisotto - Natural Wines & Hospitality"
      setLang(0)
    }
    else if(window.location.pathname.substring(1,3) === "en"){
      document.head.children[4].content = "Ca' Lovisotto - Natural Wines & Hospitality"
      setLang(1)
    }

  },[])

  const wrapperProps = {lang: lang, setLang: setLang, setNavbarHeight:setNavbarHeight};

  const commonProps = {navbarHeight: navbarHeight, testi: testiPages[lang].pages};

  const tempStyle = {"paddingTop":navbarHeight+"px"}

  return (
    <div className='app'>
      <BrowserRouter>
        <Routes>
          <Route path="/:lang" element={<Wrapper wrapperProps={wrapperProps}>
            <Home commonProps={commonProps}/>
          </Wrapper>}/>

          <Route path="/it/chi-siamo" element={<Wrapper wrapperProps={wrapperProps}>
            <Storia commonProps={commonProps}/>
          </Wrapper>}/>
          <Route path="/en/who-we-are" element={<Wrapper wrapperProps={wrapperProps}>
            <Storia commonProps={commonProps}/>
          </Wrapper>}/>

          <Route path="/it/vini" element={<Wrapper wrapperProps={wrapperProps}>
           <Vini commonProps={commonProps}/>
          </Wrapper>}/>
          <Route path="/en/wines" element={<Wrapper wrapperProps={wrapperProps}>
            <Vini commonProps={commonProps}/>
          </Wrapper>}/>

          <Route path="/it/vini/vino/:wineid" element={<Wrapper wrapperProps={wrapperProps}>
            <Vino commonProps={commonProps}/>
          </Wrapper>}/>
          <Route path="/en/wines/wine/:wineid" element={<Wrapper wrapperProps={wrapperProps}>
            <Vino commonProps={commonProps}/>
          </Wrapper>}/>

          <Route path="/it/agriturismo" element={<Wrapper wrapperProps={wrapperProps}>
            <Agriturismo commonProps={commonProps}/>
          </Wrapper>}/>
          <Route path="/en/farmhouse" element={<Wrapper wrapperProps={wrapperProps}>
            <Agriturismo commonProps={commonProps}/>
          </Wrapper>}/>

          <Route path="/it/agriturismo/camera/:roomid" element={<Wrapper wrapperProps={wrapperProps}>
            <Room commonProps={commonProps}/>
          </Wrapper>}/>
          <Route path="/en/farmhouse/room/:roomid" element={<Wrapper wrapperProps={wrapperProps}>
            <Room commonProps={commonProps}/>
          </Wrapper>}/>

          <Route path="/it/fotogallery" element={<Wrapper wrapperProps={wrapperProps}>
            <Fotogallery commonProps={commonProps}/>
          </Wrapper>}/>
          <Route path="/en/photogallery" element={<Wrapper wrapperProps={wrapperProps}>
            <Fotogallery commonProps={commonProps}/>
          </Wrapper>}/>

          <Route path="/it/news" element={<Wrapper wrapperProps={wrapperProps}>
            <News commonProps={commonProps}/>
          </Wrapper>}/>
          <Route path="/en/news" element={<Wrapper wrapperProps={wrapperProps}>
            <News commonProps={commonProps}/>
          </Wrapper>}/>

          <Route path="/it/contattaci" element={<Wrapper wrapperProps={wrapperProps}>
            <Contattaci commonProps={commonProps}/>
          </Wrapper>}/>
          <Route path="/en/contact-us" element={<Wrapper wrapperProps={wrapperProps}>
            <Contattaci commonProps={commonProps}/>
          </Wrapper>}/>

          <Route path="/it/privacy-policy" element={<Wrapper wrapperProps={wrapperProps}>
            <Privacy commonProps={commonProps}/>
          </Wrapper>}/>
          <Route path="/en/privacy-policy" element={<Wrapper wrapperProps={wrapperProps}>
            <Privacy commonProps={commonProps}/>
          </Wrapper>}/>

          <Route path="*" element={<Navigate to="/it" replace />}/> {/* qualisasi altra route riporta a /it */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;