import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './css/contattaci.css'
import { ReactComponent as Map } from './../components/footer/map.svg'
import { ReactComponent as Tick } from './../media/svg/tick.svg'
import { ReactComponent as Loading } from './../media/svg/loading.svg'

export default function Contattaci({commonProps}){

   const testi = commonProps.testi[7];

   /* resettare lo scroll ogni volta che si cambia pagina */
   const location = useLocation();
   useEffect(() => {
      document.querySelector("html").scrollTo(0,0)
   }, [location.key]);
   /* resettare lo scroll ogni volta che si cambia pagina */

   const [inputArray, setInputArray] = useState(["","","","",""])
   const [idErr, setIdErr] = useState(-1)
   const [Privacy, setPrivacy] = useState(0)

   function checkValues(){
      for(var i = 0; i < inputArray.length; i++){
         if(inputArray[i] === "" || inputArray[i] === " " || inputArray[i] === "  "){
            if(i!==3){
               return i;
            }
         }
      }
      return -1;
   }

   function CheckEmail(){
      var email = inputArray[2];
      var atpos = email.indexOf("@");
      var dotpos = email.lastIndexOf(".");
      if (atpos<1 || dotpos<atpos+2 || dotpos+2>=email.length)
      {
         return 0;
      }else{
         return 1;
      }
   }

   const [showPop, setshowPop] = useState(0)
   const [emailSent, setemailSent] = useState(0)

   function SendEmail(){
      var nome = inputArray[0];
      var cognome = inputArray[1];
      var email = inputArray[2];
      var cellulare = inputArray[3];
      var messaggio = inputArray[4];


      window.emailjs.send('service_ko6w6us', 'template_a5xoxyl', {nome,cognome,email,cellulare,messaggio})
      .then(function(response) {
         /* setPopId(2) */
         setshowPop(0)
         console.log('SUCCESS!', response.status, response.text);
         setemailSent(1)
      }, function(error) {
         /* setPopId(3) */
         setshowPop(0)
         console.log('FAILED...', error);
         setemailSent(2)
      });
   }

   function handleClick(){
      if(checkValues() !== -1){
         setIdErr(checkValues())
      }else if(!CheckEmail()){
         setIdErr(2)
      }else{
         if(Privacy){
            setshowPop(1)
            SendEmail()
         }else{
            setIdErr(5)
         }
      }
   }

   useEffect(() => {
      if(idErr != -1){
         const element = document.getElementById('input-'+idErr);
         window.scrollTo({ top: element.offsetTop-150, behavior: 'smooth' })
      }
   }, [idErr])

   return(
      <div className="mainPage" style={{"marginTop":commonProps.navbarHeight+"px"}}>
         <h1 className='contattaci-h1'>{testi.title}</h1>

         <div className='contattaci-frame'>
            <div className='contattaci-sx'>
               <div className='contattaci-subt'>
                  <h2>{testi.subt1}</h2>
                  <div className='contattaci-subtRect'></div>
               </div>

               <div className='contattaci-form'>
                  <div className='contattaci-form-row'>
                     <Input type="text" idErr={idErr} setIdErr={setIdErr} txt={testi.form.fields[0]} id={0} inputArray={inputArray} setInputArray={setInputArray}/>
                     <Input type="text" idErr={idErr} setIdErr={setIdErr} txt={testi.form.fields[1]} id={1} inputArray={inputArray} setInputArray={setInputArray}/>
                  </div>
                  <div className='contattaci-form-row'>
                     <Input type="text" idErr={idErr} setIdErr={setIdErr} txt={testi.form.fields[2]} id={2} inputArray={inputArray} setInputArray={setInputArray}/>
                     <Input type="number" idErr={idErr} setIdErr={setIdErr} txt={testi.form.fields[3]} id={3} inputArray={inputArray} setInputArray={setInputArray}/>
                  </div>

                  <InputArea idErr={idErr} setIdErr={setIdErr} txt={testi.form.fields[4]} id={4} inputArray={inputArray} setInputArray={setInputArray}/>
               </div>

               <PrivacyComponent Privacy={Privacy} setPrivacy={setPrivacy}
                  idErr = {idErr} setIdErr = {setIdErr}
                  txt={testi.form.privacy}
                  />

               <div className='navbar-contattaci cursorPointer zoom' onClick={()=>handleClick()}>
                  {testi.form.button}
               </div>
               {emailSent == 1 ? <div className='contattaci-resEmail' style={{color: "green"}}>{testi.popUp[1]}</div> : emailSent == 2 ? <div className='contattaci-resEmail' style={{color: "red"}}>{testi.popUp[2]}</div> : null}

            </div>

            <div className='contattaci-dx'>
               <div className='contattaci-subt'>
                  <h2>{testi.subt2}</h2>
                  <div className='contattaci-subtRect'></div>
               </div>

               <div className='contattaci-location ombra'>

                  <div className='contattaci-address'>
                     <div className='footer-title'>{testi.where.name}</div>
                     <div className='contattaci-txt'>{testi.where.address}</div>
                     <a className='contattaci-map-btn zoom' href={testi.where.mapBtn.link} target="_blank" alt={testi.where.mapBtn.txt}>
                        <Map/>
                        {testi.where.mapBtn.txt}
                     </a>
                  </div>

                  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11079.247211280746!2d13.4738754!3d46.0349036!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe2cb860d80cb169a!2sCa&#39;%20Lovisotto%20Natural%20Wines%20%26%20Hospitality!5e0!3m2!1sit!2sit!4v1669546879244!5m2!1sit!2sit" width="250" height="200" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

               </div>
            </div>
         </div>

         {showPop?<div className='contattaci-popUpBackground'>
            <div className='contattaci-popUp'>
               <Loading className='loadingSvg'/>
               <div>{testi.popUp[0]}</div>
            </div>
         </div>:null}
      </div>
   )
}

function HandleOnChange(id,inputArray,setInputArray,inputValue,setIdErr){
   var oldArray = inputArray;
   oldArray[id] = inputValue;
   setInputArray(oldArray)
   setTimeout(() => {
      setIdErr(-1)
   }, 300);
}

function Input ({type,txt,id,inputArray,setInputArray,idErr,setIdErr}){
   return(
      <div className='input' id={"input-"+id}>
         <span className='form-field-title' style={idErr === id?{color:"red"}:{}}>{txt.title}</span>
         <input onChange={(e)=>HandleOnChange(id,inputArray,setInputArray,e.target.value,setIdErr)} className='ombra' type={type} placeholder={txt.placeholder}/>
      </div>
   )
}

function InputArea ({txt,id,inputArray,setInputArray,idErr,setIdErr}){
   return(
      <div className='inputArea input' id={"input-"+id}>
         <span className='form-field-title' style={idErr === id?{color:"red"}:{}}>{txt.title}</span>
         <textarea onChange={(e)=>HandleOnChange(id,inputArray,setInputArray,e.target.value,setIdErr)} className='ombra' placeholder={txt.placeholder}/>
      </div>
   )
}

function PrivacyComponent({Privacy, setPrivacy, idErr, setIdErr, txt}){

   function handleClick(){
      setPrivacy(Privacy?false:true)
      setIdErr(-1)
   }

   return(
      <div className='privacy' id="input-5" onClick={()=>handleClick()}>
         <div className='tick-container'>
            <div className={Privacy ? 'checkbox active' : 'checkbox'} style={idErr === 5 ? {border: "1px solid red"} : {}}>
               <Tick style={Privacy?{}:{"display":"none"}}/>
            </div>
         </div>

         <span style={idErr === 5 ? {color: "red"} : {}}>{txt.txt.accetto}<a href={txt.link} target="_blank" rel="noopener noreferrer">{txt.txt.link}</a></span>
      </div>
   )
}