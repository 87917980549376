import './css/privacy.css'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export default function Privacy({commonProps}){
   const testi = commonProps.testi[8];

   /* resettare lo scroll ogni volta che si cambia pagina */
   const location = useLocation();
   useEffect(() => {
      document.querySelector("html").scrollTo(0,0)
   }, [location.key]);
   /* resettare lo scroll ogni volta che si cambia pagina */

   return(
      <div className="mainPage" style={{"marginTop":commonProps.navbarHeight+"px"}}>
         <h1>{testi.title}</h1>
         <p className='normalTxt privacyTxt'>{testi.txt}</p>
      </div>
   )
}