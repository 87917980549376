import './navbar.css'
import testi from '../../media/testi/navbar'
import { ReactComponent as Logo } from './logo.svg'
import { ReactComponent as LangIcon } from './language.svg'
import { ReactComponent as Burger } from './burger.svg'
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BurgerMenu from '../burger-menu/burgerMenu'

export default function Navbar({lang,setNavbarHeight,setLang,setHideBurger}){

    const ref = useRef(null);
    const oppLangArray = ["en","it"]

    const navigate = useNavigate()

    useEffect(()=>{
        const navbar = ref.current;
        setNavbarHeight(navbar.clientHeight)
    },[])

    function HandleResize(){
        const navbar = ref.current;
        setNavbarHeight(navbar.clientHeight)
    }

    useEffect(()=>{
        window.addEventListener("resize", HandleResize, false);
    },[])

    function changePage(path){
        const langArray = ["it","en"]

        if(path !== ""){
            navigate("/"+langArray[lang]+"/"+path)
        }else{
            navigate("/"+langArray[lang])
        }
    }

    const [showBurger, setShowBurger] = useState(0)
    const [timer, setTimer] = useState(0)

    useEffect(()=>{
        if(showBurger){
            setTimeout(() => {
                setTimer(1)
                setHideBurger(1)
            }, 800);
        }else{
            setHideBurger(0)
            setTimeout(() => {
                setTimer(0)
            }, 500);
        }
    },[showBurger])

    return(
        <div className="navbar ombra" ref={ref} style={showBurger&&timer?{"position":"absolute"}:{"position":"fixed"}}>
            <Logo className='navbar-logo cursorPointer' onClick={()=>changePage("")}/>
            <div className='navbar-links'>
                {
                    testi[lang].links.map((el,i)=>{
                    return <div key={i}  onClick={()=>changePage(el.path)} className="cursorPointer underline zoom">{el.name}</div>
                  })
               }

            </div>
            <div className='navbar-dx'>
                <div className='navbar-lang cursorPointer underline zoom' onClick={()=>{
                        navigate("/"+oppLangArray[lang])
                        setLang(lang?0:1)
                    }}>
                    <LangIcon className='navbar-langIcon'/>
                    <div>{testi[lang].language}</div>
                </div>
                <div className='navbar-contattaci cursorPointer zoom' onClick={()=>changePage(testi[lang].contattaci.path)}>
                    {testi[lang].contattaci.name}
                </div>
                <div className='clickableArea navbar-burger' onClick={()=>{setShowBurger(1)}}>
                    <Burger/>
                </div>
            </div>
            <BurgerMenu setShowBurger={setShowBurger} testi={testi[lang].links} changePage={changePage} contattaci={testi[lang].contattaci} showBurger={showBurger} timer={timer}/>
        </div>
    )
}