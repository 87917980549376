import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './css/news.css'

export default function News({commonProps}){

   const testi = commonProps.testi[6];
   
   /* resettare lo scroll ogni volta che si cambia pagina */
   const location = useLocation();
   useEffect(() => {
      document.querySelector("html").scrollTo(0,0)
   }, [location.key]);  
   /* resettare lo scroll ogni volta che si cambia pagina */

   return(
      <div className="mainPage" style={{"marginTop":commonProps.navbarHeight+"px"}}>
         <h1>{testi.title}</h1>
         <div className='vini-frame'>
            {testi.newsArray.map((el,i)=>{
               return <LittleNews key={i} el={el}/>
            })}
         </div>
      </div>
   )
}

function LittleNews({el}){

   return(
      <div className='news-box ombra'>
         <img src={el.img} alt={el.title}/>

         <div className='news-box-txt'>
            <div className='footer-title'>{el.title}</div>
            <div className='normalTxt'>{el.description}</div>            
         </div>
      </div>
   )
}