import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import GetAltTag from '../functions/getAltTag';
import './css/storia.css'

export default function Storia({commonProps}){

   const testi = commonProps.testi[0];


   /* resettare lo scroll ogni volta che si cambia pagina */
   const location = useLocation();
   useEffect(() => {
      document.querySelector("html").scrollTo(0,0)
   }, [location.key]);
   /* resettare lo scroll ogni volta che si cambia pagina */

   return(
      <div className="mainPage" style={{"marginTop":commonProps.navbarHeight+"px"}}>
         <h1>{testi.title}</h1>

         {testi.blocks.map((el,i)=>{
            if(i === 0){
               return <Block2 key={i} el={el} i={i}/>
            }else if(el.txt[0] === ""){
               return <img key={i} src={el.img} alt={GetAltTag(el.img)} title={GetAltTag(el.img)}/>
            }else if(el.img === ""){
               return <div key={i} className='storia-txt normalTxt ombra'>{el.txt}</div>
            }else{
               return <Block key={i} el={el} i={i}/>
            }
         })}
      </div>
   )
}

function Block({el, i}){
   return(
      <div key={i} className={i % 2 !== 0 ? 'storia-block' : 'storia-block storia-block-reversed'}>
         <img src={el.img} alt={GetAltTag(el.img)} title={GetAltTag(el.img)}/>
         <div className='storia-txt normalTxt ombra'>{el.txt}</div>
      </div>
   )
}

function Block2({el, i}){
   return(
      <div key={i} className="storia-block2">
         <img src={el.img} alt={GetAltTag(el.img)} title={GetAltTag(el.img)}/>
         <div className='normalTxt'>{el.txt}</div>
      </div>
   )
}