import './css/vini.css'
import { ReactComponent as Info } from './../media/svg/info.svg'
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export default function Vini({commonProps}){

   const testi = commonProps.testi[1];

   const navigate = useNavigate()


   /* resettare lo scroll ogni volta che si cambia pagina */
   const location = useLocation();
   useEffect(() => {
      document.querySelector("html").scrollTo(0,0)
   }, [location.key]);
   /* resettare lo scroll ogni volta che si cambia pagina */

   return(
      <div className="mainPage" style={{"marginTop":commonProps.navbarHeight+"px"}}>
         <h1>{testi.title}</h1>

         {testi.vini.map((el,i)=>{
            return <Vino key={i} el={el}/>
         })}

         <div className='navbar-contattaci cursorPointer zoom' onClick={()=>navigate(testi.infoLink)}>
            {testi.info}
         </div>
      </div>
   )
}

function Vino({el}){

   return(
      <div className='vini-box'>
         <img src={el.img} alt={el.title}/>

         <div className='vini-title-texts'>
            <div className='footer-title'>{el.title}</div>

            <div className='vini-infos'>
               {el.info.map((info,i)=>{
                  return(
                     <div className='vini-info' key={i}>
                        <span className='normalTxt vini-title-info'>{info.title}</span>
                        <span className='normalTxt vini-text-info'>{info.text}</span>
                     </div>
                  )
               })}
            </div>
         </div>
      </div>
   )
}