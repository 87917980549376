const testiNavbar = [
    {
        language: "IT",
        links:[
            {
                name: "Chi siamo",
                path: "chi-siamo"
            },
            {
                name: "I vini",
                path: "vini"
            },
            /* {
                name: "L’agriturismo",
                path: "agriturismo"
            }, */
            {
                name: "Fotogallery",
                path: "fotogallery"
            },
            /* {
                name: "News",
                path: "news"
            } */
        ],
        contattaci: {
            name: "Contattaci",
            path: "contattaci"
        }
    },
    {
        language: "EN",
        links:[
            {
                name: "Who we are",
                path: "who-we-are"
            },
            {
                name: "Wines",
                path: "wines"
            },
            /* {
                name: "Farmhouse",
                path: "farmhouse"
            }, */
            {
                name: "Photogallery",
                path: "photogallery"
            },
            /* {
                name: "News",
                path: "news"
            } */
        ],
        contattaci: {
            name: "Contact us",
            path: "contact-us"
        }
    }
]

export default testiNavbar;