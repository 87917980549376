import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './burgerMenu.css'
import { ReactComponent as Cross } from './cross.svg'
import { ReactComponent as Logo } from './logo.svg'

export default function BurgerMenu({setShowBurger,testi,changePage,contattaci,showBurger,timer}){


   /* resettare lo scroll ogni volta che si cambia pagina */
   const location = useLocation();
   useEffect(() => {
      document.querySelector("html").scrollTo(0,0)
   }, [location.key,timer]);
   /* resettare lo scroll ogni volta che si cambia pagina */

   return(
      <div className={showBurger?"burgerMenu":"burgerMenu hideBurger"}>
         <div className='clickableArea burger-cross' onClick={()=>{
               if(timer){
                  setShowBurger(0)
               }
            }}>
            <Cross/>
         </div>

         <div className='burger-links'>

            {testi.map((el,i)=> {
               return(
                  <div key={i} className='cursorPointer underline' onClick={()=>{
                     if(timer){
                        changePage(el.path)
                        setShowBurger(0)
                     }
                     }}>
                     {el.name}
                  </div>
               )
            })}
            <div className='cursorPointer underline' onClick={()=>{
                  if(timer){
                     changePage(contattaci.path)
                     setShowBurger(0)
                  }
               }}>{contattaci.name}</div>
         </div>

         <Logo className='burger-logo cursorPointer' onClick={()=>{
               if(timer){
                  changePage("")
                  setShowBurger(0)
               }
            }}/>
      </div>
   )
}