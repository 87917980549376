import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import GetAltTag from '../functions/getAltTag';
import { ReactComponent as ArrowL } from './../media/svg/arrowL.svg'
import { ReactComponent as ArrowR } from './../media/svg/arrowR.svg'
import './css/fotogallery.css'


function importAll(r) {
   let images = {};
   r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
   return images;
}

const images = importAll(require.context('./../media/immagini/fotogallery', false, /\.(png|jpe?g|svg)$/));

export default function Fotogallery({commonProps}){

   const testi = commonProps.testi[5];

   /* resettare lo scroll ogni volta che si cambia pagina */
   const location = useLocation();
   useEffect(() => {
      document.querySelector("html").scrollTo(0,0)
   }, [location.key]);
   /* resettare lo scroll ogni volta che si cambia pagina */


   var imagesArray = [];

   Object.keys(images).map((el,i)=>{
      imagesArray[i] = images[el]
   }
   )

   const [ImgIndex, setImgIndex] = useState(0)

   function HandleClick(value){
      if(value === -1 && ImgIndex === 0){
         setImgIndex(imagesArray.length-1)
      }else if(value === 1 && ImgIndex === imagesArray.length-1){
         setImgIndex(0)
      }else{
         setImgIndex(ImgIndex+value)
      }
   }

   const [ShowAll, setShowAll] = useState(8)

   const [windowWidth, setWindowWith] = useState(window.innerWidth)

   const handleResize = () => {
      setWindowWith(window.innerWidth)
   }

   useEffect(() => {
      window.addEventListener("resize", handleResize, false);
   }, []);

   function handleClickImg(i){
      const element = document.getElementById('main-img');
      setImgIndex(i)
      window.scrollTo(0,element.offsetTop-commonProps.navbarHeight-30,"smooth")
   }

   return(
      <div className="mainPage" style={{"marginTop":commonProps.navbarHeight+"px"}}>
         <h1 onClick={()=>setShowAll(8)}>{testi.title}</h1>
         <div className='fotog-block' style={ShowAll !== 8? {flexFlow:"column",gap:"50px"}:{}}>
            <div className='fotog-sx' style={ShowAll !== 8? {width:"100%"}:{}}>

               <div className='fotog-boxArrow zoom' onClick={()=>HandleClick(-1)}>
                  <ArrowL/>
               </div>

               <div id='main-img' className='fotog-img_title' style={windowWidth>750?{"height": "calc(100vh - 50px - "+commonProps.navbarHeight+"px)"}:null}>
                  <img src={imagesArray[ImgIndex]} alt={GetAltTag(imagesArray[ImgIndex])}/>
                  <div className='normalTxt'>{GetAltTag(imagesArray[ImgIndex])}</div>
               </div>

               <div className='fotog-boxArrow zoom' onClick={()=>HandleClick(+1)}>
                  <ArrowR/>
               </div>

            </div>

            <div className='fotog-dx' style={ShowAll !== 8? {width:"100%"}:{}}>
               {imagesArray.map((el,i)=>{
                  if(i<ShowAll){
                     return <img key={i} src={el} className="fotog-miniImg zoom"
                        style={ImgIndex===i?{border:"0.3vw var(--primary-100) solid"}:{}}
                        onClick={()=>handleClickImg(i)}/>
                  }else if(i===8 && ShowAll === 8){
                     return <div key={i} className='fotog-mini-last zoom' onClick={()=>setShowAll(imagesArray.length)}>
                        <img src={el} className="fotog-miniImg"
                           style={ImgIndex===i?{border:"0.3vw var(--primary-100) solid"}:{}}/>
                        <div className='fotog-mini-rect'>
                           <span className='fotog-mini-txt'>{testi.tip}</span>
                        </div>
                     </div>
                  }
               })}
            </div>
         </div>
      </div>
   )
}