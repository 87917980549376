import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Carusel from '../components/carusel/carusel';
import GetAltTag from '../functions/getAltTag';
import './css/camera.css'

function importAll(r) {
   let images = {};
   r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
   return images;
}

const images1 = importAll(require.context('./../media/immagini/rooms/room1', false, /\.(png|jpe?g|svg)$/));
const images2 = importAll(require.context('./../media/immagini/rooms/room2', false, /\.(png|jpe?g|svg)$/));
const images3 = importAll(require.context('./../media/immagini/rooms/room3', false, /\.(png|jpe?g|svg)$/));
const images4 = importAll(require.context('./../media/immagini/rooms/room4', false, /\.(png|jpe?g|svg)$/));

export default function Room({commonProps}){

   let {roomid} = useParams()

   const testi = commonProps.testi[4].rooms[roomid-1];
   
   const navigate = useNavigate()

   const [carusel, setCarusel] = useState(0)

   const [imageClicked, setImageClicked] = useState(0)

   var imagesArray = [];

   if(roomid == 1){
      Object.keys(images1).map((el,i)=>{
         imagesArray[i] = images1[el]
      }                  
      )
   }else if(roomid == 2){
      Object.keys(images2).map((el,i)=>{
         imagesArray[i] = images2[el]
      }                  
      )
   }else if(roomid == 3){
      Object.keys(images3).map((el,i)=>{
         imagesArray[i] = images3[el]
      }                  
      )
   }else{
      Object.keys(images4).map((el,i)=>{
         imagesArray[i] = images4[el]
      }                  
      )
   }

   
   /* resettare lo scroll ogni volta che si cambia pagina */
   const location = useLocation();
   useEffect(() => {
      document.querySelector("html").scrollTo(0,0)
   }, [location.key]);  
   /* resettare lo scroll ogni volta che si cambia pagina */

   return(
      <div className="mainPage" style={{"marginTop":commonProps.navbarHeight+"px"}}>
         <h1>{testi.title}</h1>

         <div className='room-block'>
            <img src={testi.mainImg} alt={GetAltTag(testi.mainImg)}/>
            <div className='room-txt ombra'>
               <div className='footer-title normalTxt'>{testi.descTitle}</div>
               <div className='normalTxt'>{testi.descTxt}</div>
            </div>
         </div>

         <div className='room-images'>
            {
               imagesArray.map((el,i)=>{
                     return <img key={i} className=" zoom" alt={GetAltTag(el)} src={el} onClick={()=>{
                        setCarusel(1)
                        setImageClicked(i)
                     }}/>
                  }                  
               )
            }
         </div>
         
         {carusel?<Carusel setCarusel={setCarusel} idClicked={imageClicked} imagesArray={imagesArray}/>:null}

         <div className='navbar-contattaci cursorPointer zoom' onClick={()=>navigate(testi.infoLink)}>
            {testi.info}
         </div>
      </div>
   )
}