import './footer.css'
import testi from '../../media/testi/footer'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Logo } from './logo.svg'
import { ReactComponent as Map } from './map.svg'
import { ReactComponent as Leaf } from './../../media/svg/Leaf bio europe.svg'
import { useEffect, useState } from 'react'

export default function Footer({hideBurger,lang}){

   const navigate = useNavigate()
   const windowWidth = window.innerWidth

   function changePage(path){
      const langArray = ["it","en"]

      if(path !== ""){
         navigate("/"+langArray[lang]+"/"+path)
      }else{
         navigate("/"+langArray[lang])
      }
   }

   useEffect(()=>{
      /* setWindowWidth(window.innerWidth) */
      /* console.log(window.innerWidth) */
   },[])

   return(
      <div className='footer' style={hideBurger?{"display":"none"}:{}}>

        <div className='footer-separatore'/>

         <div className='footer-sx footer-child'>
            <Logo className='footer-logo cursorPointer' onClick={()=>changePage("")}/>

            <div className='footer-txt'>{testi[lang].address}</div>

            <a className='map-btn footer-txt cursorPointer zoom' href={testi[lang].mapBtn.link} target="_blank" alt={testi[lang].mapBtn.txt}>
               <Map/>
               {testi[lang].mapBtn.txt}
            </a>
         </div>

         <div className='footer-menu footer-child'>

            <div className='footer-logoEu'>
               <Leaf id='footer-leaf'/>
               <span>{testi[lang].codiciBio[0]}</span>
               <span>{testi[lang].codiciBio[1]}</span>
               <span>{testi[lang].codiciBio[2]}</span>
            </div>
         </div>

         <div className='footer-dxChild footer-child'>
            <div className='footer-title'>{testi[lang].contatti.name}</div>

            <div className='footer-flexSocials'>
               {
                  testi[lang].contatti.socials.map((el,i)=>{
                     return <a key={i} className={["cursorPointer "+el.class]} target='_blank' href={el.link} alt={el.class}><el.icon/></a>
                  })
               }
            </div>

            <div className='footer-flexContatti'>
               {
                  testi[lang].contatti.links.map((el,i)=>{
                     return <a key={i} className="cursorPointer underline footer-txt zoom" target='_blank' href={el.link} alt={el.name}>{el.name}</a>
                  })
               }
            </div>
         </div>
         <a className='footer-privacy cursorPointer underline footer-txt zoom' href={testi[lang].privacy.link} target='_blank'>{testi[lang].privacy.txt}</a>
      </div>
   )
}