import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import './css/vino.css'

export default function Vino({commonProps}){

   let {wineid} = useParams()
   const testi = commonProps.testi[2]/* .rooms[wineid-1] */;
   console.log(testi)

   
   /* resettare lo scroll ogni volta che si cambia pagina */
   const location = useLocation();
   useEffect(() => {
      document.querySelector("html").scrollTo(0,0)
   }, [location.key]);  
   /* resettare lo scroll ogni volta che si cambia pagina */

   return(
      <div className="mainPage" style={{"marginTop":commonProps.navbarHeight+"px"}}>

      </div>
   )
}